<template>
  <div class="box">
    <!-- <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
    <el-table
    :data="data"
    style="width: 100%"
    row-key="id"
    accordion
    lazy
    :load="load"
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
    <el-table-column
      label="单位名称" style="padding-left: 80px;">
      <template slot-scope="scope"  style="margin-left: 20px;">
        <span>{{scope.row.label}}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="操作"
      width="300"
      align="center">
      <template slot-scope="scope">
         <el-button size="mini" type="primary" @click="Add(scope.row)">新 增</el-button>
         <el-button size="mini" type="danger" @click="Delete(scope.row)">删 除</el-button>
      </template>
    </el-table-column>
  </el-table>
      <!-- 新增单位 -->
    <el-dialog
      title="新增单位"
      :visible.sync="adduserVisible"
      width="700"
      center>
      <el-form
        :model="ruleForm2"
        status-icon
        :rules="rules2"
        ref="ruleForm2"
        label-width="0"
        class="demo-ruleForm"
      >
       <el-form-item prop="name" label="单位名称" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.name" auto-complete="off" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.description" auto-complete="off" placeholder="请输入描述，非必填"></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button @click="adduserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { Classification, addUnit, delDel } from '@/api/training.js'
  export default {
    name: 'Leavefer',
    data () {
      return {
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        data: [],
        adduserVisible: false,
        ruleForm2: {
          name: '',
          description: ''
        },
        rules2: {
          name: [{ required: true, message: '请输入单位名称', trigger: 'blur' }]
        },
        id: null,
        formLabelWidth: '100px'
      }
    },
    mounted () {
      this.list()
    },
    methods: {
       load(tree, treeNode, resolve) {
        setTimeout(() => {}, 1000)
      },
     handleNodeClick(data) {
       console.log(data)
     },
      list() {
        const parenId = 0
        Classification(parenId).then(res => {
          if (res.data.code === '0') {
            this.data = res.data.data
          }
        })
      },
      Add(row) {
        console.log(row)
        this.id = row.id
        this.adduserVisible = true
      },
      // 新增单位名称
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const query = {
              value: this.ruleForm2.name,
              description: this.ruleForm2.description,
              parentId: this.id
            }
            addUnit(query).then((resp) => {
              this[ruleForm2] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  message: '新增成功。',
                  type: 'success'
                })
                this.list()
                this.adduserVisible = false
              } else {
                this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
          }
        })
      },
       // 删除
      Delete (row) {
        const id = row.id
        this.$confirm('确定删除此用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delDel(id).then(res => {
            if (res.data.code === '0') {
              this.$notify({
                  message: '删除成功。',
                  type: 'success'
              })
              this.list()
            } else {
              this.$notify.error({
                message: res.data.msg
              })
            }
          })
        }).catch(() => {
        })
      },
      ...mapMutations({
        saveUser: 'SAVE_USER'
      })
    }
  }
</script>
<style>
.box .el-table--mini, .el-table--small, .el-table__expand-icon{font-size: 14px;}
</style>
<style scoped>
.box {padding: 30px; background: #FFF;}
</style>
